function init(videoElements) {
	let volumeByScript = false

	const volumeObserver = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				if (!entry.target.paused) {
					const videoElement = entry.target
					const volume = easeInOutCubic(entry.intersectionRatio)

					volumeByScript = true

					videoElement.volume = Math.min(
						volume,
						parseFloat(videoElement.dataset.maxVolume || 1)
					)
				}
			})
		},
		{
			// Create an array of thresholds from 0 to 1 at intervals of 0.01
			threshold: Array.from(Array(101).keys()).map((x) => x / 100),
		}
	)

	videoElements.forEach((videoElement) => {
		volumeObserver.observe(videoElement)

		// Handle user adjusting the volume of the video
		videoElement.addEventListener('volumechange', () => {
			if (!volumeByScript) {
				videoElement.dataset.maxVolume = videoElement.volume
			}
			volumeByScript = false
		})
	})
}

function easeInOutCubic(x) {
	return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
}

export { init }
