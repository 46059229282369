import { wrapChildren } from './utils'

function init() {
	const navbar = document.querySelector('.navbar')
	const navbarNav = navbar.querySelector('.navbar-nav')
	const navbarToggler = navbar.querySelector('.navbar-toggler')

	if (navbarToggler) {
		const menuId = navbarToggler.dataset.bsTarget

		/**
		 * Menu animations are in another component so the page loads faster
		 */
		if (menuId) {
			const menu = document.getElementById(menuId.substr(1))

			if (menu) {
				import('./menu-animation').then(module => module.init(menu))

				menu.addEventListener('show.bs.offcanvas', () => {
					navbarToggler.classList.add('active')
				})

				menu.addEventListener('hide.bs.offcanvas', () => {
					navbarToggler.classList.remove('active')
				})
			}
		}

		/**
		 * Position of the navbar toggler
		 */
		let navbarTogglerClone

		function setNavbarTogglerPosition() {
			navbarToggler.style.position = 'static'
			const navbarRect = navbarToggler.getBoundingClientRect()
			navbarToggler.style.position = 'fixed'

			if (!navbarTogglerClone) {
				navbarTogglerClone = document.createElement('div')
				navbarTogglerClone.className = 'navbar-toggler-placeholder'
				navbarToggler.parentElement.insertBefore(
					navbarTogglerClone,
					navbarToggler
				)
				navbarTogglerClone.appendChild(navbarToggler)
			}

			navbarTogglerClone.style.width = Math.floor(navbarRect.width) + 'px'
			navbarTogglerClone.style.height = Math.floor(navbarRect.height) + 'px'
		}

		window.addEventListener('resize', setNavbarTogglerPosition)
		window.addEventListener('load', setNavbarTogglerPosition)
	}

	if (navbarNav) {
		const navbarLinks = navbarNav.querySelectorAll('a')

		/**
		 * Navbar underline that follows the cursor
		 */
		if (navbarLinks.length > 0) {
			navbarNav.style.position = 'relative'

			const navbarLinkUnderline = document.createElement('div')
			navbarLinkUnderline.classList.add('navbar-link-underline')
			navbarLinkUnderline.style.transition = 'none'
			navbarNav.appendChild(navbarLinkUnderline)

			function setActiveNavbarLink(link = navbarNav.querySelector('a.active span')) {
				if (link) {
					navbarLinkUnderline.style.setProperty('--x', link.offsetLeft)
				}
				navbarLinkUnderline.style.setProperty('--w', `${link ? link.offsetWidth : 0}`)
			}

			navbarLinks.forEach(link => {
				const wrappedLink = wrapChildren(link)
				link.addEventListener('mouseenter', () => setActiveNavbarLink(wrappedLink))
			})

			setActiveNavbarLink()
			navbarLinkUnderline.style.transition = ''

			navbarNav.addEventListener('mouseleave', () => setActiveNavbarLink())
			window.addEventListener('resize', () => setActiveNavbarLink())
			window.addEventListener('load', () => setActiveNavbarLink())
		}
	}
}

export {
	init,
}
