function init(videoElements, threshold = 0) {
	let pauseByScript = false

	const playbackObserver = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				const videoElement = entry.target

				if (entry.isIntersecting) {
					if (videoElement.dataset.userPaused !== 'true') {
						videoElement.play()
					}
				} else {
					pauseByScript = true
					videoElement.pause()
				}
			})
		},
		{ threshold }
	)

	videoElements.forEach((videoElement) => {
		playbackObserver.observe(videoElement)

		// Handle user manually pausing the video
		videoElement.addEventListener('pause', () => {
			if (!pauseByScript) {
				videoElement.dataset.userPaused = 'true'
			}
			pauseByScript = false
		})

		// Handle user manually playing the video
		videoElement.addEventListener('play', () => {
			videoElement.dataset.userPaused = 'false'
		})
	})
}

export { init }
