function init() {
	const shareButton = document.querySelectorAll('[data-share-button')

	shareButton.forEach(element => {
		if (navigator.share) {
			element.hidden = false

			try {
				element.addEventListener('click', async () => {
					await navigator.share({
						title: document.title,
						text: document.querySelector('meta[name="description"]')?.content,
						url: window.location.href
					})
				})
			} catch (err) {
				console.error(err)
			}
		} else {
			element.hidden = true
		}
	})
}

export {
	init
}
