/**
 * Wrap children in another element
 * @param {HTMLElement} parent - The element which children will be wrapped
 * @param {HTMLElement} wrapper - The element which will wrap the children
 * @returns {HTMLElement} - The wrapper element
 */
export function wrapChildren(parent, wrapper = document.createElement('span')) {
	if (typeof wrapper === 'string') {
		wrapper = document.createElement(wrapper)
	}

	while (parent.firstChild) {
		wrapper.appendChild(parent.firstChild)
	}

	parent.appendChild(wrapper)

	return wrapper
}

/**
 * Get current page by body class name "page-*"
 * @returns {string} Page name
 */
export function getCurrentPage() {
	const pageClass = document.body.className.match(/page-[^ ]+/)
	return pageClass ? pageClass[0].replace('page-', '') : ''
}

/**
 * Hide elements with the data-attribute `hide-before-animation` before animation
 * @param {boolean} hide - Whether to hide or show the elements, defaults to `true`
 * @param {HTMLElement} context - The context to search for elements, defaults to `document`
 * @returns {void}
 */
export function hideBeforeAnimation(hide = true, context = document) {
	const elements = context.querySelectorAll('[data-before-animation]')
	elements.forEach((el) => (el.dataset.beforeAnimation = hide))
}

/**
 * Make an element animateable by wrapping it in a `span` and adding required styles
 * @param {HTMLElement} element - The element to make animateable
 * @returns {HTMLElement} - The animateable element
 */
export function animateable(element) {
	const animatedElement = document.createElement('span')
	animatedElement.style.display = 'inline-block'

	element.style.overflow = 'hidden'

	wrapChildren(element, animatedElement)

	return animatedElement
}

/**
 * Get all siblings of an element
 * @param {HTMLElement} element
 * @returns {Array}
 */
export function getSiblings(element) {
	// for collecting siblings
	let siblings = []
	// if no parent, return no sibling
	if (!element.parentNode) {
		return siblings
	}
	// first child of the parent node
	let sibling = element.parentNode.firstChild

	// collecting siblings
	while (sibling) {
		if (sibling.nodeType === 1 && sibling !== element) {
			siblings.push(sibling)
		}
		sibling = sibling.nextSibling
	}
	return siblings
}

/**
 * @param {number} value The numeric value to be converted based on the direction.
 * @param {boolean} direction A boolean flag that determines the sign of the output. If true, the output will be negated.
 * @returns {number} The original value multiplied by -1 if direction is true, otherwise the original value.
 */
export function directionalValue(value, direction) {
	return value * (direction ? -1 : 1)
}

/**
 * @param {HTMLElement} element The element to calculate the scroll fraction of
 * @returns {number} The scroll fraction of the element between 0 and 1
 */
export function getScrollFraction(element) {
	const rect = element.getBoundingClientRect()
	const totalHeight = element.scrollHeight

	// How much of the element is scrollable beyond the viewport top
	const scrollableDistance = totalHeight - window.innerHeight
	if (scrollableDistance <= 0) {
		return 0 // The element is not scrollable
	}

	// The fraction of the element's height that has been scrolled
	// Negative values are set to zero and values over one are capped at one
	const scrolledFraction = Math.min(
		Math.max(0, -rect.top / scrollableDistance),
		1
	)

	return scrolledFraction
}

/**
 * Check for iterability
 * @param  {*} obj
 */
export function isIterable(obj) {
	return obj == null ? false : typeof obj[Symbol.iterator] === 'function'
}

/**
 * Convert anything to an array
 * @param  {*} obj
 */
export function toArray(obj) {
	return obj == null ? [] : isIterable(obj) ? [...obj] : [obj]
}

/**
 * Remove inline styles from an element
 * @param {HTMLElement} element Element to remove the styles from
 * @param  {...string} props    Styles to remove
 */
export function removeStyle(element, ...props) {
	props.forEach((prop) => {
		element.style[prop] = ''
		if (element.getAttribute('style') === '') {
			element.removeAttribute('style')
		}
	})
}

export function elementInViewport(el) {
	let top = el.offsetTop
	let left = el.offsetLeft
	const width = el.offsetWidth
	const height = el.offsetHeight

	while (el.offsetParent) {
		el = el.offsetParent
		top += el.offsetTop
		left += el.offsetLeft
	}

	return (
		top >= window.scrollY &&
		left >= window.scrollX &&
		top + height <= window.scrollY + window.innerHeight &&
		left + width <= window.scrollX + window.innerWidth
	)
}

function vws() {
	const vws = document.documentElement.clientWidth / 100
	document.documentElement.style.setProperty('--vws', `${vws}px`)
}

export function setVws(element = document.body) {
	vws()
	const ro_vws = new ResizeObserver(vws)
	ro_vws.observe(element)
}

export function oscillator({
	time,
	freq = 1,
	amp = 1,
	phase = 0,
	offset = 0,
	waveform = 'sine',
}) {
	let value

	switch (waveform) {
		case 'sine':
			value = Math.sin(time * freq * Math.PI * 2 + phase * Math.PI * 2)
			break
		case 'cosine':
			value = Math.cos(time * freq * Math.PI * 2 + phase * Math.PI * 2)
			break
		default:
			console.warn('Unknown waveform.')
			break
	}

	return value * amp + offset
}

export function mergeObjects(obj1, obj2) {
	const descriptors1 = Object.getOwnPropertyDescriptors(obj1)
	const descriptors2 = Object.getOwnPropertyDescriptors(obj2)
	const mergedDescriptors = Object.assign({}, descriptors1, descriptors2)
	return Object.create(Object.getPrototypeOf(obj1), mergedDescriptors)
}

export function randomBetween(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min
}
