import { toArray, removeStyle } from './utils'

let elements = []

function init(elems = '[data-parallax]') {
	elements = toArray(
		(typeof elems == 'string')
			? document.querySelectorAll(elems)
			: elems
	)

	if (elements.length) {
		calcPositions()

		window.addEventListener('scroll', calcPositions)
		window.addEventListener('resize', calcPositions)
	}
}

function calcPositions() {
	if (!elements.length) return

	const scrollTop = window.scrollY
	const windowWidth = window.innerWidth
	const windowHeight = window.innerHeight

	elements.forEach(element => {
		const elementOffset = element.offsetTop
		const elementHeight = element.offsetHeight
		const elementWidth = element.scrollWidth

		// skip if element is above or below viewport
		if (elementOffset + elementHeight < scrollTop || elementOffset > scrollTop + windowHeight) {
			return
		}

		const offset = scrollTop + windowHeight - elementOffset - elementHeight
		const speed = (windowHeight - (elementHeight / 2)) / (elementWidth - windowWidth)
		const value = Math.round(offset / -speed)

		element.style.transform = `translate3d(${value}px, 0, 0)`
	})
}

function destroy(...elems) {
	// Destroy all elements if no arguments are passed
	if (!elems.length) {
		elems = elements
	}

	elems.forEach(element => {
		removeStyle(element, 'transform')
	})

	elements = elements.filter(element => !elems.includes(element))

	if (!elements.length) {
		window.removeEventListener('scroll', calcPositions)
		window.removeEventListener('resize', calcPositions)
	}
}

export {
	init,
	calcPositions,
	destroy,
}
