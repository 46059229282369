/**
 * Responsive video source using media queries
 * @param {Object} options
 * @param {boolean} options.resize Reevaluate the source when the window is resized
 * @param {boolean} options.debug Log debug messages
 * @param {HTMLVideoElement[]} elements The elements to apply the responsive video to
 * @example
 * <video poster="poster-2x3.png">
 * 	<source data-media="(min-width: 62em)" data-poster="poster-4x3.png" data-src="video-4x3.mp4" type="video/mp4">
 * 	<source data-media="(min-width: 40em)" data-poster="poster-1x1.png" data-src="video-1x1.mp4" type="video/mp4">
 * 	<source src="video-2x3.mp4" type="video/mp4">
 * 	Your browser does not support the video tag.
 * </video>
 */
export function videoResponsiveSource(
	options,
	elements = document.getElementsByTagName('video')
) {
	options = {
		resize: true,
		debug: false,
		...options,
	}

	/**
	 * Select the first source that matches the media query
	 * @TODO select source for each type/codec (maybe using `canPlayType`)
	 * @param {HTMLSourceElement[]} sources The list of sources to select from
	 * @returns {HTMLSourceElement|undefined} The selected source
	 */
	function selectSource(sources) {
		sources = Array.from(sources)

		const source = sources.find((source) => {
			return window.matchMedia(source.dataset.media).matches
		})

		return source ? source : sources.find((source) => !source.dataset.media)
	}

	function init() {
		for (let i = 0; i < elements.length; i++) {
			const element = elements[i]

			if (element.tagName !== 'VIDEO') {
				if (options.debug) {
					console.log('not a video element', element)
				}
				return
			}

			const source = selectSource(element.getElementsByTagName('source'))

			if (source) {
				if (options.debug) {
					console.groupCollapsed('new source candidate', { element, source })
				}

				// Select the source either from `src` or `data-src` attribute
				const src = source.getAttribute('src') || source.dataset.src

				if (!src) {
					if (options.debug) {
						console.log(
							'skipping because candidate has no `src` or `data-src` attribute'
						)
						console.groupEnd()
					}
					return
				}

				if (new URL(src, window.location.href).href === element.currentSrc) {
					if (options.debug) {
						console.log(
							'skipping because candidate is already the current source'
						)
						console.groupEnd()
					}
					return
				}

				if (options.debug) {
					console.log('selected candidate as new source')
				}

				const { currentTime, paused } = element

				element.src = src

				// If currentTime is not 0, then the video is/was playing and we need
				// to restore play/pause state and seek to the correct time
				if (currentTime) {
					if (paused) {
						element.pause()

						if (options.debug) {
							console.log('pausing video')
						}
					}

					element.currentTime = currentTime

					if (options.debug) {
						console.log('seeking to', currentTime)
					}

					if (!paused) {
						element.play()

						if (options.debug) {
							console.log('playing video')
						}
					}
				}

				// Set the poster only if the video has not been started yet
				if (source.dataset.poster && paused && !currentTime) {
					element.poster = source.dataset.poster

					if (options.debug) {
						console.log('setting poster to', source.dataset.poster)
					}
				}

				if (options.debug) {
					console.groupEnd()
				}
			}
		}
	}

	init()

	const debouncedInit = debounce(init, 300)

	if (options.resize) {
		window.addEventListener('resize', debouncedInit)
	}

	return {
		reload: init,
		destroy: () => {
			if (options.resize) {
				window.removeEventListener('resize', debouncedInit)
			}
		},
	}
}

/**
 * Debounce a function
 * @param {Function} func The function to debounce
 * @param {number} wait The time to wait before calling the function
 * @param {boolean} immediate Whether to call the function immediately
 */
function debounce(func, wait, immediate = false) {
	var timeout
	return function () {
		var context = this,
			args = arguments
		var later = function () {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		var callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}
