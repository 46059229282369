import MatchHeight from 'matchheight'

// Custom modules
import { getCurrentPage, hideBeforeAnimation, setVws } from './utils'
import { init as navbar } from './navbar'
import { init as parallax } from './parallax'
import { init as share } from './share'
import { videoResponsiveSource } from './video-responsive-source'
import { init as videoAutoplayInview } from './video-autoplay-inview'
import { init as videoAudioDriveby } from './video-audio-driveby'
import { init as iframemanager } from './iframemanager'

if (process.env.NODE_ENV !== 'development') {
	// overwrite console.log
	console.log = () => { }
}

function handleModule(module, ...options) {
	module.then(module => {
		hideBeforeAnimation(false)
		module.init(...options)
	}).catch(error => {
		console.error(error)
		hideBeforeAnimation(false)
	})
}

function loadRouteModules() {
	const page = getCurrentPage()

	switch (page) {
		case 'join':
			handleModule(import(`./pages/join`))
			break
		case 'join-jobs':
			handleModule(import(`./pages/join-jobs`))
			break
		case 'approach':
			handleModule(import(`./pages/approach`))
			break
		case 'case-vorwerk':
			handleModule(import(`./pages/case-vorwerk`))
			break
		default:
			break
	}
}

/**
 * INIT
 *
 * This function initializes all modules used on the site, try to keep it as
 * simple as possible moving any complicated logic into the modules itself.
 */
const INIT = function () {
	import('bootstrap/js/src/offcanvas')

	const mh = new MatchHeight()
	window.addEventListener('load', () => mh.update())

	setVws()
	hideBeforeAnimation()

	// Init modules
	iframemanager()
	navbar()
	parallax()
	share()
	videoResponsiveSource()

	// Init video autoplay and audio driveby
	const videoElements = document.querySelectorAll('video[data-autoplay-inview]')
	if (videoElements.length) {
		videoAutoplayInview(videoElements)
		videoAudioDriveby(videoElements)
	}

	// Load filter
	const filterElement = document.getElementById('filter')
	if (filterElement) {
		import('./filter').then(module => module.init(filterElement))
	}

	// Load section animation
	const sectionElements = document.querySelectorAll(
		'.page-main > :is(.section, .container > .section):not([data-disable-animation])'
	)
	if (sectionElements.length) {
		sectionElements.forEach(section => section.dataset.beforeAnimation = true)
		handleModule(import('./section-animation'), { sections: sectionElements })
	}

	// Load page specific modules
	loadRouteModules()

	// Load flickity
	if (document.querySelector('[data-slider]')) {
		handleModule(import(`./slider`))
	}

	if (document.querySelector('[data-lavalamp]')) {
		const elems = [...document.querySelectorAll('[data-lavalamp]')]

		import('./lavalamp').then((module) => {
			elems.map((elem) => {
				const options = elem.dataset.lavalamp
					? JSON.parse(elem.dataset.lavalamp)
					: {}
				module.init(elem, options)
			})
		})
	}

	if (document.querySelector('[data-scroll-horizontal]')) {
		const elems = [...document.querySelectorAll('[data-scroll-horizontal]')]
		import('./scroll-horizontal').then((module) => elems.map(module.init))
	}

}

document.addEventListener('DOMContentLoaded', INIT)
