import '@orestbida/iframemanager'

export const init = () => {
	const im = window.iframemanager()

	window.addEventListener('load', () => {
		if (!window.OneTrust) return

		OneTrust?.OnConsentChanged((event) => {
			if (event.detail.includes('4')) {
				im.acceptService('youtube')
			} else {
				im.rejectService('youtube')
			}
		})
	})

	im.run({
		currLang: document.documentElement.getAttribute('lang'),
		onChange: ({ changedServices, eventSource }) => {
			if (eventSource.type === 'click' && changedServices.includes('youtube')) {
				OneTrust?.UpdateConsent('Category', '4:1')
			}
		},
		services: {
			youtube: {
				embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}?rel=0',
				thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',
				iframe: {
					allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
				},

				languages: {
					en: {
						notice: 'This content is hosted by a third party. By showing the external content you accept the <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">terms and conditions</a> of youtube.com.',
						loadBtn: 'Load video',
						loadAllBtn: "Don't ask again"
					},
					'de-DE': {
						notice: 'Dieser Inhalt wird von einem Dritten gehostet. Durch das Anzeigen der externen Inhalte akzeptieren Sie die <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">Allgemeine Geschäftsbedingungen</a> von youtube.com.',
						loadBtn: 'Video laden',
						loadAllBtn: "Nicht erneut fragen"
					}
				}
			}
		}
	});
}

